import { Modal as MantineModal } from "@mantine/core";
import classNames from "classnames";
import * as classes from "./Modal.css";

export function Modal(
  props: React.ComponentProps<typeof MantineModal> & {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    isShort?: boolean;
    isWide?: boolean;
    isMenu?: boolean;
  }
) {
  const { header, footer, children, isWide, isShort, isMenu, ...rest } = props;

  return (
    <MantineModal
      {...rest}
      classNames={{
        content: classNames(classes.content, {
          [classes.contentWide]: isWide,
          [classes.contentShort]: isShort,
          [classes.isMenu]: isMenu,
        }),
        header: classes.header,
        close: classes.close,
        overlay: classes.overlay,
        body: classes.body,
        ...props.classNames,
      }}
    >
      {header}
      {children}
      {footer}
    </MantineModal>
  );
}

import { Autocomplete as MantineAutocomplete } from "@mantine/core";
import * as classes from "./Autocomplete.css";

export function Autocomplete(
  props: React.ComponentProps<typeof MantineAutocomplete>
) {
  return (
    <MantineAutocomplete
      {...props}
      classNames={{
        input: classes.input,
        label: classes.label,
        dropdown: classes.dropdown,
        option: classes.option,
      }}
    />
  );
}
